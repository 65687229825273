import React from 'react';
import classNames from 'classnames';
import {Block} from "../Block";
import {BlockTitle} from "../BlockTitle";
import {PageMainColumnWrapper} from "../PageMainColumnWrapper";
import {SubserviceLink} from "../SubserviceLink";
import {BlockWithHeader} from "../BlockWithHeader";

import * as styles from './ServiceV2SubservicesBlock.module.scss'

export function ServiceV2SubservicesBlock({title, subservices, ...otherProps}) {


    return <BlockWithHeader withTypicalMargin appearance="subtle"
                            title={title}
                            {...otherProps}>
        <div className={styles.subservices}>
            {subservices.map((x, i) => {
                return <SubserviceLink
                    key={i}
                    className={styles.subserviceItem}
                    title={x.title}
                    description={x.description}
                    icon={x.icon}
                    slug={x.slug}
                    hasContent={x.hasContent}
                />;
            })}
        </div>
    </BlockWithHeader>
}
